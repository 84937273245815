import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import ServiceDetailsContentTesting from '../components/ServiceDetails/ServiceDetailsContentTesting'
//import RelatedServices from '../components/ServiceDetails/RelatedServices'
 
const Details = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Testing Frameworks" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Service Details Testing" 
            />
            <ServiceDetailsContentTesting />
            <Footer />
        </Layout>
    );
}

export default Details