import React from 'react'
import ServiceSidebarTesting from './ServiceSidebarTesting'
import details1 from '../../assets/images/services/services-details1.jpg'
import project2 from '../../assets/images/projects/project2.jpg'
import charts from '../../assets/images/services/charts.jpg'

const ServiceDetailsContentTesting = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={details1} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <span className="sub-title">Testing Frameworks Services</span>
                            <h3>About</h3>
                            <p>Part of any project the testing phase is one of the most important ones to ensure that all business logics are covered.</p>

                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>We Support</h3>
                                        <ul>
                                            <li>Automation</li>
                                            <li>Data Generation</li>
                                            <li>Data Anonymization</li>
                                            <li>Performance</li>
                                            <li>Orchestration</li>
                                            <li>Life Cycle</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <p>Supported by the best tool on the market, developing Testing Frameworks custom for individual complex enterprises becomes easy. We have experience in developing testing frameworks upon complex life cycles covering business logic use-cases, data anonymization for lower-environments where security policies comes into place, until automation and deployment for various development releases. By ensuring those steps, we help our clients reduce considerably the unforeseen issues and covering all known business and technical data use-cases.</p>
                            <p>Correlating profiles, data discovery results, overall analytics insights together with business rules engines, we can automate the process for data generation for testing purposes to ensure the environment's completeness and that no unforeseen events take place. Even thought it might seem tedious designing the framework, the outcome overcompensates large testing teams and lack of trust in results, even reducing architecture components for intermediate physical and logical resource allocation.</p>
                            <h3>Application Areas</h3>

                            <div className="row">
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-investment"></i>
                                        </div>
                                        Banking
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-factory"></i>
                                        </div>
                                        Manufacturing
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
                                        Healthcare
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-tracking"></i>
                                        </div>
                                        Automotive
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Real Estate
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Logistics
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebarTesting />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContentTesting